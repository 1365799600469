import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './components/layout/Header';
import Todos from './components/Todos';
import AddTodo from './components/AddTodo';
import About from './components/pages/About';
import uuid from 'uuid';
import './App.css';


class App extends Component {

	markComplete = (id) => {
		console.log(id)
		this.setState({ todos: this.state.todos.map(todo => {
			if(todo.id === id) {
				todo.completed = !todo.completed
			}
			return todo;
		}) });
	}

	delTodo = (id) => {
		console.log(id)
		this.setState({ todos: [...this.state.todos.filter(todo => todo.id !== id)] });
	}
	
	addTodo = (title) => {
		const newTodo = {
			id: uuid.v4(), 
			title, 
			completed: false
		}
		this.setState({ todos: [...this.state.todos, newTodo] });
	}
	state = {
		todos: [
		{
			id: 1, 
			title: 'Take out the trash',
			completed: false
		},
		{
			id: 2, 
			title: 'Give Corwin a big kiss',
			completed: false
		},
		{
			id: 3, 
			title: 'Build React todos app',
			completed: true
		}
		]
	}

	render() {
		return (
			<Router>
				<div className='App'>
					<div className='container'>
						<Header />
						<Route
							exact
							path='/'
							render={(props) => (
								<React.Fragment>
									<AddTodo addTodo={this.addTodo} />
									<Todos
										todos={this.state.todos}
										markComplete={this.markComplete}
										delTodo={this.delTodo}
									/>
								</React.Fragment>
							)}
						/>
						<Route path='/about' component={About} />
					</div>
				</div>
			</Router>
		);
	}
}

export default App;
